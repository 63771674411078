body {
  background-color: #000000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  padding-top: 10vh;
  padding-bottom: 9vh;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
}

.Title {
  min-height: 5vh;
  padding-bottom: 10vh;
}

.App-sub-header1 {
  font-size: calc(10px + 6vmin);
}

.App-sub-header2 {
  font-size: calc(10px + 2vmin);
  color: #d1d1d1;
}

.App-sub-header3 {
  min-height: 5vh;
  padding-bottom: 5vh;
  font-size: calc(10px + 2vmin);
  color: #d1d1d1;
}
